<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 17:19:15
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-25 14:38:20
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\video\detail.vue
 * @Description: 视频播放
 *
-->
<template>
  <div class="fixed-page culture-video-detail">
    <Breadcrumb class="breadcrumb-1400" />
    <div class="container">
      <div class="left" :key="id">
        <p class="overflow-ellipsis font-bold">
          {{ detail.title }}
        </p>
        <video
          id="video"
          :src="detail.source"
          controls
          width="1040"
          height="585"
          :muted="true"
          :autoplay="true"
          controlsList="nodownload"
        ></video>
        <div class="info-line">
          <div class="flex-center">
            <span v-for="tag in detail.tag" :key="tag" class="tag">{{
              tag
            }}</span>
          </div>
          <div class="flex-center">
            <span>已播：{{ detail.hits || "-" }}</span>
            <span style="margin-left: 1.0417vw">转发到：</span>
            <div
              class="flex-center share-box"
              :data-clipboard-text="href"
              @click="share"
            >
              <c-image :src="require('@/assets/img/performance/icon-dy.png')" />
              <c-image :src="require('@/assets/img/performance/icon-wx.png')" />
              <c-image :src="require('@/assets/img/performance/icon-wb.png')" />
            </div>
          </div>
        </div>
        <div class="flex-center">
          <span class="extra-info">发布人：{{ detail.author }}</span>
          <span class="extra-info">发布时间：{{ detail.publishDate }}</span>
        </div>
      </div>
      <div class="right">
        <p class="overflow-ellipsis font-bold">推荐视频</p>
        <div
          v-for="item in rightList"
          :key="item.id"
          class="video-item"
          @click="handleDetail(item)"
        >
          <c-image :src="item.image" :alt="item.title" />
          <p>
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getSysHomeConfigPage } from "@/service/system";
import { getArticleDetail, articleHit } from "@/service/culture";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      dayjs,
      id: this.$route.params.id,
      detail: {},
      rightList: [],
      href: "",
    };
  },

  created() {
    this.getData();
    this.getRight();
    this.href = location.href;
  },
  watch: {
    $route() {
      if (
        this.$route.path.startsWith("/culture/video/detail") &&
        this.$route.params.id !== this.id
      ) {
        this.id = this.$route.params.id;
        this.getData();
      }
    },
  },
  methods: {
    async getRight() {
      const res = await getSysHomeConfigPage({
        pageNo: 1,
        pageSize: 3,
        condition: {
          level1: "videoRight",
        },
      });
      this.rightList = (res.data?.data || []).map((item) => ({
        ...item,
        tag: item.tag ? item.tag.split(",") : [],
      }));
    },
    async getData() {
      try {
        const res = await getArticleDetail(this.id);
        const data = res.data || {};
        data.tag = data.tag ? data.tag.split(",") : [];

        this.detail = data;

        this.click();
      } catch (e) {
        console.log(e);
      }
    },
    // 查看详情
    handleDetail(item) {
      window.open(`#/culture/video/detail/${item.targetId}`);
    },
    // 点击量+1
    click() {
      this.detail.id && articleHit(this.detail.id);
    },
    share() {
      var clipboard = new Clipboard(".share-box");
      clipboard.on("success", () => {
        this.$message({
          title: "成功",
          message: "复制成功",
          type: "success",
          duration: 2000,
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message({
          title: "复制失败",
          message: "该浏览器不支持自动复制",
          type: "error",
          duration: 2000,
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-video-detail {
  p {
    padding: 0;
    margin: 0;
  }
  .font-bold {
    font-weight: bold;
  }
  .container {
    margin: 0 auto 2.6042vw;
    box-sizing: border-box;
    width: 72.9167vw;
    padding: 1.5625vw;
    background-color: #ffffff;
    display: flex;
    font-size: 1.25vw;
    .share-box {
      cursor: pointer;
    }
    .left {
      margin-right: 2.2917vw;
    }
    #video {
      margin: 1.5625vw 0;
      width: 54.1667vw;
      height: 30.4688vw;
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .info-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.7292vw;
      font-weight: 400;
      color: #212122;
      .tag {
        font-size: 0.625vw;
        color: #212122;
        background: #f8d39f;
        border-radius: 0.1042vw;
        height: 1.0417vw;
        line-height: 1.0417vw;
        padding: 0 0.4167vw;
        margin-right: 0.625vw;
      }
      .img {
        width: 1.25vw;
        height: 1.25vw;
        & + .img {
          margin-left: 0.5208vw;
        }
      }
    }
    .extra-info {
      margin-top: 1.0417vw;
      font-size: 0.7292vw;
      font-weight: 400;
      color: #909399;
      & + .extra-info {
        margin-left: 3.125vw;
      }
    }
    .video-item {
      margin-top: 1.5625vw;
      font-size: 0.9375vw;
      cursor: pointer;
      .img {
        width: 13.3333vw;
        height: 7.5vw;
        margin-bottom: 0.5208vw;
      }
      p {
        .text-overflow(2);
      }
    }
  }
}
</style>
